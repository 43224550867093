export const router = {
  home: {
    en: `/`,
  },
  ir35: {
    en: `/ir35`,
  },
  about: {
    en: `/about-us`,
  },
  "about.awards": {
    en: `/about-us/awards`,
  },
  "about.diversity": {
    en: `/about-us/diversity-and-inclusion`,
  },
  "about.diversity.programme-one": {
    en: `/about-us/diversity-and-inclusion/programme-one`,
  },
  team: {
    en: `/consultants`,
  },
  "team.member": {
    en: `/consultants/[url_slug]`,
  },
  blog: {
    en: `/blog`,
  },
  "blog.item": {
    en: `/blog/[url_slug]`,
  },
  "blog.tag": {
    en: `/blog/tag/[url_slug]`,
  },
  events: {
    en: `/events`,
  },
  "events.item": {
    en: `/events/[url_slug]`,
  },
  "events.tag": {
    en: `/events/tag/[url_slug]`,
  },
  careers: {
    en: `/work-for-us`,
  },
  contact: {
    en: `/contact-us`,
  },
  "contact.form.complete": {
    en: `/contact-us/submission-complete`,
  },
  "submit-job": {
    en: "/submit-a-job",
  },
  "form.complete": {
    en: `/submission-complete`,
  },
  "form.register": {
    en: `/register`,
  },
  hiring: {
    en: `/hiring-solutions`,
  },
  "hiring.permanent": {
    en: `/hiring-solutions/permanent-recruitment`,
  },
  "hiring.contract": {
    en: `/hiring-solutions/contract-recruitment`,
  },
  specialism: {
    en: `/specialisms`,
  },
  "specialism.item": {
    en: `/specialisms/[url_slug]`,
  },
  jobs: {
    en: `/jobs`,
  },
  "jobs.form.complete": {
    en: `/jobs/application-complete`,
  },
  "jobs.item": {
    en: `/jobs/[url_slug]`,
  },
  "jobs.specialism": {
    en: `/jobs/#/[url_slug]`,
  },
  "popular.roles": {
    en: "/[url_slug]",
  },
  "policy.impressum": {
    en: `/impressum`,
  },
  "policy.dpn": {
    en: `/data-privacy-notification`,
  },
  "policy.privacy": {
    en: `/privacy-policy`,
  },
  "policy.terms": {
    en: `/terms-and-conditions`,
  },
  "policy.cookies": {
    en: `/cookies-policy`,
  },
  "policy.slavery": {
    en: `/modern-slavery-statement`,
  },
  sitemap: {
    en: "/sitemap",
  },
};
