export const sourceflowEnums = {
  forms: {
    register: {
      en: "0006b73b-cbab-4be0-8b56-0043caf82322",
    },
    contactUs: {
      en: "42f6bc58-ad7b-47d9-8a31-da30a8d70bbd",
    },
    eventSignup: {
      en: "9a77327e-fb5e-450c-a716-4e91bba1b65c",
    },
    ir35: {
      en: "0c293198-9647-48a1-962f-5cb6d6bfb206"
    }
  },
  categories: {
    sectors: {
      id: "fa91b0cd-36a6-4039-85a0-d074f4946724",
      technology: "5de6756f-a851-42aa-9308-47d15f109115",
      insurance: "d8a0c124-acb8-4f5a-97cb-fa4638528fd8",
    },
    specialisms: {
      id: "269bcda8-1233-40eb-8c43-d96cb98ced68",
    },
    type: {
      id: "780444c4-719d-4660-a4de-e41fffc445e0",
      contract: "9fc0f558-4404-4249-bee7-0b4865bb07e7",
      permanent: "d1991fba-66f9-45ca-911f-e98a8f8b8881",
      both: "c2fdbc99-9eeb-42b9-a603-63c46f6992b7",
    },
    working_environment: {
      id: "48e28df0-8201-4cdc-ac9b-3f107fae949e",
      any: "d074d412-7687-4ffb-8b5d-5899beab65b3",
      hybrid: "71552a5c-4180-4f34-bd72-8300f28f7cd5",
      onsite: "4ef2cb0e-4078-4246-80a6-0e89e0da962d",
      remote: "79a79580-ee57-4080-84d2-5fbde50bd030",
    },
  },
  config: {
    baseUrl: "https://www.gravitasgroup.co.uk",
    siteName: "Gravitas Recruitment Group UK",
    socialLogo:
      "https://media.licdn.com/dms/image/C4D0BAQHN9687UaD-WQ/company-logo_200_200/0/1630579047955/gravitas_recruitment_group_uk_logo?e=2147483647&v=beta&t=y-gvCgEAM8WC-AzexuKI8iNgiJFtdab_W-IPJabbacA",
    recruitmentTypes: {
      contract: "contract",
      permanent: "permanent",
      array: ["contract", "permanent"],
    },
  },
};

export default sourceflowEnums;
